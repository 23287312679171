<template>
  <page-title-component title="Licenses"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <button class="btn btn-sm btn-primary mr-2"
                      v-if="user && ![6, 8].includes(user.id)"
                      @click="$refs.licenseFormModal.show()">Create License</button>

              <button class="btn btn-sm btn-primary"
                      @click="createCampaign"
                      v-if="selectedUserIds.length && user && ![6, 8].includes(user.id)">Bulk Send</button>
            </div>
            <div class="col-4">
              <input type="text"
                     v-model="search"
                     placeholder="Search"
                     class="form-control">
            </div>
          </div>

          <hr>

          <table class="table table-hover table-striped table-bordered">
            <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Type</th>
              <th>Activated At</th>
              <th>Revoked At</th>
              <th>Activated Email</th>
              <th>Note</th>
              <th class="text-right">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="license in sortedLicenses" :key="'license_'+license.id">
              <td>
                <input type="checkbox"
                       v-if="!license.revoked_at && license.usedBy && license.usedBy.settings.aweber_owner_account_id"
                       :value="license.usedBy.id"
                       v-model="selectedUserIds">
              </td>
              <td>{{ license.name }}</td>
              <td>
                <span v-if="license.type === 'full'">Full License</span>
                <span v-else>Collab License</span>
              </td>
              <td>{{ license.activated_at }}</td>
              <td>{{ license.revoked_at }}</td>
              <td>{{ license.usedBy ? license.usedBy.email : '' }}</td>
              <td>{{ license.note }}</td>
              <td class="text-right no-wrap">
                <button class="btn btn-sm btn-info"
                        v-if="license.account_user_id && !license.revoked_at"
                        @click="gotoManageAccount(license)">
                  Manage
                </button>

                <button class="btn btn-sm btn-info ml-1"
                        @click="$refs.editNoteModal.show(license)">
                  Note
                </button>

                <button class="btn btn-sm btn-danger ml-1"
                        v-if="license.usedBy && !license.revoked_at"
                        @click="revoke(license)">X</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <license-form-modal @added="loadData"
                      ref="licenseFormModal" />

  <edit-note-modal @updated="loadData" ref="editNoteModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import LicenseFormModal from "@/views/licenses/LicenseFormModal.vue";
import EditNoteModal from "@/views/licenses/EditNoteModal.vue";
import {mapGetters} from "vuex";
export default {
  components: {PageTitleComponent, LicenseFormModal, EditNoteModal},
  data() {
    return {
      licenses: [],
      search: '',
      selectedUserIds: [],
      queryParams: {
        search: '',
        search_columns: '',
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 5000
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    sortedLicenses() {
      let licenses = [...this.licenses];

      if (this.search) {
        licenses = licenses.filter(license => license.name.toLowerCase().includes(this.search.toLowerCase()) ||
          license.usedBy.email.toLowerCase().includes(this.search.toLowerCase()));
      }

     return licenses;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    createCampaign() {
      this.$store.commit('createCampaign/setSelectedUserIds', this.selectedUserIds);
      this.$router.push({name: 'create_campaign'})
    },
    gotoManageAccount(row) {
      window.open(process.env.VUE_APP_USER_URL + '?accountUser=' + row.account_user_id, "_blank");
    },
    create() {
      this.$swal({
        title: 'Are you sure want to create?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/licenses')
            .then(() => {
              this.loadData();
            });
        }
      })
    },
    revoke(row) {
      this.$swal({
        title: 'Are you sure want to revoke?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/licenses/revoke/' + row.id)
            .then(() => {
              this.loadData();
            });
        }
      })
    },
    loadData() {
      this.licenses = [];

      this.axios.get('/licenses', {params: this.queryParams})
        .then((response) => {
          this.licenses = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>